import { trackInfoCardImpression } from "./event/trackInfoCardImpression";
import { trackOpenMenu } from "./event/trackOpenMenu";
import { trackPageLeave } from "./event/trackPageLeave";
import { trackPremiumInformationBoxImpression } from "./event/trackPremiumInformationBoxImpression";
import { trackSalesPosterImpression } from "./event/trackSalesPosterImpression";
import { trackSubscriptionClick } from "./event/trackSubscriptionClick";
import { trackTeaserClick } from "./event/trackTeaserClick";
import { trackInfoCardClick } from "./event/trackInfoCardClick";
import { trackTeaserImpression } from "./event/trackTeaserImpression";
import { trackViewArticle } from "./event/trackViewArticle";
import { trackViewFrontpage } from "./event/trackViewFrontpage";
import { trackViewNewsMixPage } from "./event/trackViewNewsMixPage";
import { trackViewSJPartnerPage } from "./event/trackViewSJPartnerPage";

const usePulse = () => {
  return {
    trackOpenMenu,
    trackViewArticle,
    trackViewFrontpage,
    trackViewNewsMixPage,
    trackViewSJPartnerPage,
    trackTeaserClick,
    trackInfoCardClick,
    trackTeaserImpression,
    trackPremiumInformationBoxImpression,
    trackSalesPosterImpression,
    trackSubscriptionClick,
    trackPageLeave,
    trackInfoCardImpression,
  };
};

export { usePulse };
